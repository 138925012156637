body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'PlayfairDisplay';
  font-style: normal;
  font-weight: normal;
  src: local('PlayfairDisplay'), url('./assets/fonts/PlayfairDisplay-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'GT Walsheim Pro';
  font-style: normal;
  font-weight: normal;
  src: local('GT Walsheim Pro'), url('./assets/fonts/GT-Walsheim-Pro-Regular-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat'), url('./assets/fonts/Montserrat-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Poppins-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins-Regular'), url('./assets/fonts/Poppins-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Visby CF';
  font-style: normal;
  font-weight: normal;
  src: local('Visby CF'), url('./assets/fonts/Fontspring-DEMO-visbycf-regular.otf') format('woff');
}