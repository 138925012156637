.about {
    padding: 84px 0px;
}

.about .mission{
    font-size: 42px;
    font-family: 'PlayfairDisplay';
    color: #052405;
    font-weight: 600;
    margin-bottom: 14px;
}

.about .circle-icon{
    margin-right: 20px;
}

.about .main_para{
    font-size: 14px;
    font-family: 'PlayfairDisplay';
    color: #000000;
    list-style: none;
}

.about .mission-right {
    width: 100%;
    height: 186px;
    overflow: hidden;
}

.mission2 {
    padding: 100px;
}
.gallery {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.mission2 .title {
    margin-bottom: 34px;
    font-size: 42px;
    font-family: 'PlayfairDisplay';
    font-weight: bold;
    text-align: center;
    color: #0B390C;
}

.star-points .title{
    color: #0B390C;
    font-family: 'PlayfairDisplay';
    font-size: 25px;
    font-weight: bold;
}
.star-points .desc{
    color: #000000;
    font-family: 'Poppins-Regular';
    font-size: 12px;
    font-weight: 500;
}

.star-points .sub-para{
    font-size: 14px;
    font-family: Montserrat;
    color: #000;
    margin-top: 12px;
}