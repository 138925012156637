.home-banner{
    background-image: url('../image//homeBanner.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}

.home-banner .title{
    font-size: 71px;
    /* font-size: 61px; */
    font-family: 'PlayfairDisplay';
    color: #FFFFFF;
    font-weight: 600;
}

.home-banner .para{
    font-size: 16px;
    font-family: 'Poppins-Regular';
    color: #fff;
    margin-bottom: 34px;
}

.home-banner .shop-best{
    font-family: 'GT Walsheim Pro';
    color: #fff;
    font-size: 16px;
    background-image: linear-gradient(to right, #919191 , #1B1C1E);
    width: 182px;
    padding: 4px 22px;
}

.home-banner .btn-container{
    display: flex;
    /* justify-content: center; */
  }

.home-banner .learnMoreBtn{
    border: 1px solid #fff;
    color: #fff;
    padding: 10px 60px;
    margin-left: 28px;
}

.home-banner .learnMoreBtn:hover{
    background-image: linear-gradient(to right, #A2D858 , #51A851);
    color: #fff;
}

.home-banner .shopNowBtn{
    background-image: linear-gradient(to right, #A2D858 , #51A851);
    color: #fff;
    padding: 10px 60px;
}

.home-banner .shopNowBtn:hover{
    background-image: unset;
    background-color: transparent;
    border: 1px solid #fff;
}

.home-banner .content-container{
    padding: 115px 20px;
}

.home-cate-slider {
    margin-top: -82px;
}

.home-cate-slider .cate-card{
    width: 100%;
    background-size: contain;
    padding: 90px 55px 44px 40px;
    object-fit: contain;
    height: 270px;
    background-repeat: no-repeat;
    overflow: hidden;
    border-radius: 12px;
}

.home-cate-slider .cate-card .content .title{
    font-family: 'GT Walsheim Pro';
    font-size: 25px;
    color: #fff;
}

.home-cate-slider .cate-card .content .para{
    font-family: 'Poppins-Regular';
    font-size: 14px;
    margin: 0;
    color: #fff;
}

.home-cate-slider .cate-card .content .link{
    font-family: 'GT Walsheim Pro';
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
    margin: 0;
}

.new-arrival .newarrival-title{
    font-family: PlayfairDisplay;
    font-size: 61px;
    color: #0B390C;
    font-weight: bold;
    text-align: center;
    margin-top: 65px;
}

.new-arrival .sub-para {
    font-family: GT Walsheim Pro;
    font-size: 16px;
    text-align: center;
}

/* card start  */
.home-product-list{
    padding: 30px 0px;
}

.product-card {
    margin-top: 20px;
    margin-bottom: 20px;
}

.product-card .product-img{
    width: 100%;
    overflow: hidden;
}

.product-card .card{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.product-card .card:hover{
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 20px 11px;
}
.product-card .product-img img{
    width: 100%;
}

.product-card .content {
    padding: 15px 6px;
}

.product-card .title {
    font-family: Montserrat;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #282828;
}

.product-card .price {
    font-family: Montserrat;
    text-align: center;
    color: #8a8a8b;
}

.product-card .rating-box{
    text-align: center;
}

/* card end  */

.deal-banner-count{
    background-image: url('../image/addBanner1.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    /* height: 300px; */
}

.deal-banner-count .sm-deal {
    color: #fff;
    font-size: 16px;
    margin: 0;
    font-family: GT Walsheim Pro;
}

.deal-banner-count .mainTitle{
    font-size: 61px;
    font-family: 'PlayfairDisplay';
    color: #FFFFFF;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 6px;
    margin-top: -12px;
}

.deal-banner-count .para{
    font-family: Poppins-Regular;
    font-size: 16px;
    color: #FFFFFF;
}

.deal-banner-count .banner-right {
    height: 100%;
    width: 100%;
    display: flex;
    /* overflow: hidden; */
    justify-content: center;
}

.deal-banner-count .banner-right img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    bottom: -114px;
}

.deal-banner-count .banner-content {
    padding: 80px 0px;
}

.counter-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.counter-container .counter-card {
    height: 70px;
    width: 70px;
    background-color: #ffffff59;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.counter-container .counter-card p {
    margin: 0px;
    color: #ffff;
    font-size: 16px;
    text-align: center;
}

/* top products  */
.top-products {
    padding: 74px 0px;
}

.top-products .sub-para {
    font-family: GT Walsheim Pro;
    font-size: 16px;
    text-align: center;
}

.top-products .newarrival-title {
    font-family: PlayfairDisplay;
    font-size: 61px;
    color: #0B390C;
    font-weight: bold;
    text-align: center;
}

.alibaba-cover {
    background-image: url('../image/alibabaBanner.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
}

.alibaba-cover .banner-content {
    padding: 80px 0px;
}

.alibaba-cover .mainTitle{
    font-size: 61px;
    font-family: 'PlayfairDisplay';
    color: #FFFFFF;
    font-weight: 500;
    /* font-weight: bold; */
}

.alibaba-cover .deal{
    color: #fff;
    font-size: 16px;
    margin: 0;
}
.alibaba-cover .para{
    font-family: Poppins-Regular;
    font-size: 16px;
    color: #FFFFFF;
}

.alibaba-cover .shop_now{
    background-image: linear-gradient(to right, #A2D858 , #51A851);
    color: #fff;
    padding: 10px 50px;
    font-family: "Visby CF";
    border-radius: 8px;
    border-radius: 4px;
    border: unset;
}

.alibaba-cover .shop_now:hover{
   background-image: unset;
    background-color: #fff;
    color: #A2D858;
}

.small-banners .cbd-cont{
    background-image: url('../image/cbd-small.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    /* height: 300px; */
}

.small-banners .tshirt-cont{
    background-image: url('../image/tshirt-small.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    /* height: 300px; */
}

.small-banners .inner-container {
    padding: 90px 158px 84px 22px;
    color: #ffff;
}

.small-banners .inner-container .title{
    font-size: 25px;
    font-family: 'GT Walsheim Pro';
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
}

.small-banners .inner-container .para{
    font-size: 14px;
    font-family: 'Poppins-Regular';
    margin: 0;
    margin-bottom: 8px;
}

.small-banners .inner-container .link{
    font-size: 14px;
    color: #fff;
    font-family: 'GT Walsheim Pro';
    text-decoration: underline;
}

/* testimonials */
/* .testimonial{
    padding: 95px 0px;
}

.testimonial .title {
    font-family: GT Walsheim Pro;
    font-size: 32px;
    color: #0B390C;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}
.testimonial .sub-para {
    font-family: GT Walsheim Pro;
    font-size: 16px;
    text-align: center;
    margin-bottom: 90px;
}

.testi-card{
    padding: 65px 65px;
    width: 70%;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    text-align: center;
    margin: 0 auto;
    position: relative;
}

.testi-card .profile{
    height: 100px;
    width: 100px;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: -54px;
}

.testi-card .profile img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.testi-card .para{
    font-family: 'GT Walsheim Pro';
    color: #848484;
    font-size: 14px;
}

.testi-card .clients{
    font-size: 19px;
    font-family: 'GT Walsheim Pro';
    color: #282828;
    margin-bottom: 8px;
} */


/* testimonails starts here */
.testimonails {
    padding: 80px 0px;
    /* background-image: url(../image/testimonail-bg.png); */
    background-repeat: no-repeat;
    background-position: top;
  }
  .testimonails-wrapper .rating-wrapper ul {
    padding: 0px;
    list-style: none;
    display: flex;
    justify-content: center;
  }
  .testimonails-wrapper .rating-wrapper ul li {
    margin: 0px 8px 0px 0px;
    color: #ffd400;
  }
  .testimonails-wrapper {
    background: #fff;
    padding: 18px;
    box-shadow: 0px 0px 9px 3px #bbbbbb26 !important;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 16%);
    /* -webkit-box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 16%); */
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
    margin-top: 44px;
    margin-bottom: 5px;
    width: 724px !important;
    margin-left: 5px;
    transform: scale(.9);
  }
  /* .testimonails-wrapper {
    background: #fff;
    padding: 18px;
    box-shadow: 0px 0px 10px 2px #00000017;
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.16);
  margin-top: 44px;
  margin-bottom: 5px;
  width: 724px !important;
  margin-left: 5px;
  } */
  .testimonails .slick-prev:before {
    content: "\f104";
    font-family: "FontAwesome";
    /* background: url(../img/arrow-bg.png); */
    padding: 29px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 1;
    background-position: center;
  }
  .testimonails .slick-prev {
    left: -22%;
  }
  .testimonails .slick-next:before {
    content: "\f105";
    font-family: "FontAwesome";
    /* background: url(../img/arrow-bg.png); */
    padding: 29px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 1;
    background-position: center;
    z-index: 9;
  }
  .testimonails .slick-next {
    right: -22%;
    z-index: 99;
  }
  .testimonails-slider::after {
    content: url(../image/Union.png);
    position: absolute;
    right: 10%;
    top: 0px;
  }
  .testimonails-slider::before {
    content: url(../image/Union.png);
    position: absolute;
    left: 10%;
    top: 0px;
    z-index: 99;
  }
  .testimonails-wrapper p {
    font-family: 'GT Walsheim Pro';
    color: #848484;
    font-size: 14px;
    margin-top: 22px;
    padding: 0px 68px;
  }
  .testimonails-wrapper h6 {
    font-family: 'GT Walsheim Pro';
    color: #282828;
    font-size: 19px;
  }
  .testimonails h2 {
    color: #0b390c;
    font-family: 'GT Walsheim Pro';
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .testimonails-wrapper img {
    margin-top: -62px !important;
    margin: 0px auto;
  }
  /* testimonails ends here */