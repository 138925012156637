/* Header css Start Here */
/* link button hover */
a:hover {
    text-decoration: none !important;
  }
  /* link button hover */
  
  .main-header a#navbarDropdown {
    padding: 0px;
  }
  
  /* Brands Page Content Css */
  .showcase .content h2 {
    font-family: "HighSpeed";
  }
  /* Brands Page Content Css */
  
  /* Secondary Header css Start Here */
  header .secondaryHeader {
    background: linear-gradient(57deg, black, #00000087);
    padding: 10px 0;
  }
  /* .secondaryHeader .active {
    border-bottom: 4px solid #fff;
  } */
  header .secondaryHeader ul.secondary-items {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
    padding: 0;
  }
  header .secondaryHeader ul.secondary-items li {
    display: inline-block;
  }
  header .secondaryHeader ul.secondary-items li a {
    font-family: HighSpeed;
    text-decoration: none;
    color: #000;
  }
  header .secondaryHeader ul.secondary-items li a i {
    color: #000;
    font-size: 25px;
  }
  header .secondaryHeader ul.secondary-items li a.downloads {
    opacity: 0.6;
  }
  header .secondaryHeader ul.secondary-items li:last-child {
    margin-right: 0;
  }
  /* Secondary header Css End Here */
  
  /* Main header css Start Here */
  header .main-header {
    background: transparent;
  }
  header .main-header2 {
    background: #000;
  }
  header .main-header .logoDv {
    margin-top: 10px;
  }

  .logoDv figure a img {
    width: 65%;
  }

  header .navbarMenu {
    display: flex;
    justify-content: space-between;
  }
  header .navbarMenu ul.navItems {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  header .navbarMenu ul.navItems li.menu-items {
    display: inline-block;
    padding-right: 40px;
  }
  header .navbarMenu ul.navItems li.menu-items:last-child {
    padding-right: 0px;
  }
  header .navbarMenu ul.navItems li.menu-items.btn {
    padding-left: 0;
    padding-right: 0;
  }
  header .navbarMenu ul.navItems li.menu-items.btn a {
    background-image: linear-gradient(to right, #A2D858 , #51A851);
    color: #fff;
    padding: 10px 50px;
    font-family: "Montserrat-Regular";
    border-radius: 8px;
  }
  header .navbarMenu ul.navItems li.menu-items.btn a:hover {
    background-image: unset;
   background-color: #fff;
    color: #51A851;
  }
  header .navbarMenu ul.navItems li.menu-items a {
    font-family: Visby CF;
    color: #fff;
    text-decoration: none;
    padding-bottom: 5px;
    font-size: 15px;
  }
  header .navbarMenu ul.navItems li.menu-items a.active {
    border-bottom: 3px solid #94d057;
    font-weight: bold;
  }
  /* Main Header Css End here */
  /* mobile header start here */
  header .mobile-header {
    width: 30%;
    height: 100%;
    position: fixed;
    background: #000;
    top: 0;
    z-index: 1;
    right: 0;
    padding-top: 30px;
    transition: 0.7s;
    transform: translateX(100%);
    overflow-y: scroll;
  }
  header .mobile-header.show {
    transform: translateX(0%);
  }
  header .mobile-header ul.mobile-nav {
    margin-bottom: 0;
    padding: 0;
    list-style: none;
  }
  header .mobile-header .cancel {
    background: #fff;
    width: 35px;
    /* text-align: center; */
    height: 35px;
    line-height: 55px;
    margin: 0px 20px 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header .mobile-header ul.mobile-nav li.nav-item {
    padding: 10px 0;
    border: 0;
    border-bottom: 1px solid #dddddd4a;
  }
  header .mobile-header ul.mobile-nav li.nav-item a {
    color: #fff;
    font-size: 14px;
    padding: 0 20px;
  }
  header .mobile-header .secondary-items {
    list-style-type: none;
    padding: 0px !important;
  }
  header .mobile-header .secondary-items li {
    padding: 5px 22px !important;
    line-height: 33px;
    border-bottom: 1px solid;
  }
  .mobile-header ul li a {
    font-family: "Montserrat-Regular";
    font-size: 17px !important;
    color: #fff !important;
  }
  .canvas-icon.text-white {
    display: none;
  }
  .navbarMenu .canvas-icon i.fa.fa-bars {
    font-size: 27px;
  }
  /* mobile header end here */
  /* header responsive queries start here*/
  @media (max-width: 1198px) {
    .canvas-icon.text-white {
      display: block;
    }
    .secondaryHeader {
      display: none;
    }
    header .main-header .logoDv {
      margin: 0px !important;
    }
  }
  @media (max-width: 1198px) {
    header ul.navItems {
      display: none;
    }
  }
  @media (max-width: 576px) {
    header .mobile-header {
      width: 50%;
    }
  }
  
  /* header responsive queries start here*/
  /* Header css End here */
  