.pages{
    padding: 80px 0px;
}
.pages h2{
    font-size: 32px;
    color: #0B390C;
    font-family: 'PlayfairDisplay';
    font-weight: bold;
    margin-bottom: 18px;
    margin-top: 18px;
}

.pages p{
    font-size: 14px;
    color: #5E5E5E;
    font-family: 'Montserrat';
    font-weight: 400;
}

.pages .title{
    font-size: 14px;
    font-family: 'Poppins-Regular';
    color: #508D00;
    font-weight: 600;
}