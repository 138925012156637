.login .login-inner{
    padding: 75px 24px;
}

.login .login-inner .input-cont{
    margin-top: 18px;
    margin-bottom: 6px;
}

.login .login-inner .input-cont2{
    margin-top: 10px;
    margin-bottom: 20px;
}

.login .title{
    font-size: 45px;
    font-family: 'PlayfairDisplay';
    color: #0B390C;
    font-weight: bold;
}

.login .main-para{
    font-family: GT Walsheim Pro;
    font-size: 15px;
    color: #000000;
}

.login input{
    width: 100%;
    padding: 8px 14px;
    border: 1px solid #bfbfbf;
}

.login .label {
    font-size: 16px;
    font-family: 'GT Walsheim Pro';
    color: #272727;
}

.login .right-sec{
    padding: 278px 28px;
    text-align: center;
}

.login .register-btn{
    color: #fff;
    background-image: linear-gradient(to right, #A2D858 , #51A851);
    padding: 10px 40px;
    border: unset;
}

.login .register-btn:hover{
    color: #51A851;
    background-image: unset;
    background-color: transparent;
    border: 1px solid #51A851;
}

.login .signin-btn{
    color: #fff;
    background-image: linear-gradient(to right, #A2D858 , #51A851);
    padding: 10px 40px;
    border: unset;
    width: 100%;
    margin-top: 28px;
}

.login .signin-btn:hover{
    color: #51A851;
    background-image: unset;
    background-color: transparent;
    border: 1px solid #51A851;
}

.login .remember-main{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.login .field-set{
    padding-right: 75px;
}

.login .remember-cont{
    display: flex;
    justify-content: center;
    align-items: center;
}
.login .remember-cont input{
    width: auto;
    padding: 0;
    border: unset;
}

.login .remember-cont label{
    font-size: 13px;
    margin-left: 6px;
    font-family: 'GT Walsheim Pro';
    margin-top: 10px;
}
.login a{
    font-size: 13px;
    margin-left: 6px;
    font-family: 'GT Walsheim Pro';
    margin-top: 10px;
    color: #000;;
}

.login a:hover{
   text-decoration: underline;
}