.footer{
    background-color: #F8F8F8;
    padding: 55px 0px 0px 0px;
}
.footer ::placeholder {
    color: #fff;
  }
.footer .icon-style-f{
    color: #51A851;
    font-size: 18px;
}

.footer .para{
    font-size: 15px;
    font-family: 'Poppins-Regular';
    color: #616161;
    margin-top: 20px;
    margin-bottom: 34px;
}

.footer .greenTitle{
    font-size: 22px;
    font-family: 'Poppins-Regular';
    color: #286F29;
}

.footer .footer-flex{
    display: flex;
    justify-content: center;
}

.footer .inner-cont {
    background-color: #0B390C;
    color: #ffff;
    padding: 45px 15px;
}

.footer .inner-cont hr {
    width: 18%;
    margin-top: -12px;
    color: #fff;
    background: #fff;
    border-color: #fff;
    height: 1px;
    opacity: 1;
    margin-bottom: 28px;
}

.footer .inner-cont .title{
    font-size: 18px;
    font-family: 'Poppins-Regular';
    /* margin-bottom: 28px; */
}

.footer .inner-cont .subscribe{
    width: 100%;
    background: #fff;
    margin-top: 10px;
    font-size: 16px;
    font-family: 'Poppins-Regular';
}

.footer .inner-cont .subscribe:hover{
    width: 100%;
    background: #286F29;
    color: #fff;
}

.footer input{
    padding: 8px 18px;
    width: 100%;
    border-color: #fff;
    background: transparent;
}

footer::placeholder{
    color: #fff;
}

.footer .copy-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px;
}

.footer a {
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins-Regular';
}

.footer a:hover{
   text-decoration: underline !important;
}
.footer .copy-right .text {
    font-size: 16px;
    color: #286F29;
    font-family: 'Poppins-Regular';
    margin: 0;
}

.footer .copy-right .footer-link{
    height: 38px;
    width: 38px;
    background-image: linear-gradient(to right, #A2D858 , #51A851);
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center; 
    margin-right: 24px;
}

.footer .copy-right .footer-link:hover{
    background-image: linear-gradient(to right, #96b171 , #567a56);
}

.footer .copy-right .sub-text {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    margin: 0;
    margin-bottom: 22px;
}

.footer .sub-text{
    font-size: 12px;
    font-family: 'Poppins-Regular';
}

.footer .link-div{
    padding: 8px 0px;
}

.footer .icon-style{
    font-size: 22px;
}

.footer .footer-logo{
    width: 30%;
}