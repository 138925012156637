.bg-header {
  background: #fff;
}
.bg-header ul.navbar-nav li.nav-item a {
  color: #000;
}
.profile-row .order-table-wrapper table {
  width: 99% !important;
  margin-left: 3px;
}
figure {
  margin: 0;
}

.profile-img .profile-upload {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0px;
  opacity: 0;
}
/* .profile-img .edit-container {
    background-color: #f7f7f7;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    margin-left: 80px;
    margin-top: 90px;
    box-shadow: 2px 2px 2px rgb(175 174 174 / 25%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-img .edit-container input {
    position: absolute;
    margin-left: 1px;
    width: 24px;
    opacity: 0;
  } */

.profile .user-upload{
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.profile .user-upload .left-side{
  display: flex;
  justify-content:flex-start;
  align-items: center;
}

.profile .user-upload .left-side .img-cont{
  height: 80px;
  width: 80px;
  overflow: hidden;
  object-fit: contain;
}

.profile .user-upload .left-side .img-cont img{
  height: 100%;
  width: 100%;
}

.profile .user-upload .left-side .upload-text{
  margin-bottom: 5px;
  font-size: 18px;
  font-family: 'GT Walsheim Pro';
  color: #282828;
  margin-left: 13px;
  text-align: initial;
}
.profile .up-container{
  position: relative;
  overflow: hidden;
  height: 38px;
  width: 180px;
  object-fit: contain;
  margin-left: 30px;
}

.profile .upload-picture{
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 20px;
  font-family: "Montserrat";
  font-weight: 600;
  color: #ffffff;
  font-size: 7px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to right, #A2D858 , #51A851);
  padding: 16px 42px;
  border: unset;
}

.profile .upload-hide{
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0px;
  opacity: 0;
}


.profile .address-main{
  margin-top: 44px;
}

.profile .address-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile .address-top .home-title {
  font-size: 16px;
  font-family: "GT Walsheim Pro";
  color: #282828a3;
}

.profile .address-top a {
  font-size: 18px;
  font-family: "GT Walsheim Pro";
  color: #0b390c;
  text-decoration: underline;
}

.profile .address-top .desc {
  font-size: 20px;
  font-family: "GT Walsheim Pro";
  color: #282828;
}

.profile .location {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.profile  .location .main {
  margin: 0;
  font-size: 17px;
  font-family: "GT Walsheim Pro";
  color: #282828;
}
.profile .location .sub {
  margin: 0;
  font-size: 14px;
  font-family: "GT Walsheim Pro";
  color: #989898;
  margin-left: 2px;
}

.profile .hr-line{
  color: #989898;
}


.profile .wish-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 40px 30px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.profile .wish-card:hover{
  box-shadow: rgb(0 0 0 / 24%) -1px 1px 20px 2px;
}

.profile .wish-card .product-main {
  display: flex;
}

.profile .wish-card .btn-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile .wish-card .pro-detail {
  margin-left: 24px;
}

.profile .wish-card .pro-detail .title {
  font-family: "GT Walsheim Pro";
  font-size: 16px;
  color: #274d5e;
  margin-bottom: 2px;
}

.profile .wish-card .pro-detail .stock {
  font-family: "GT Walsheim Pro";
  font-size: 14px;
  color: #274d5e;
  margin-bottom: 2px;
}
.profile .wish-card .pro-detail .stock span {
  color: #00bc26;
}

.profile .wish-card .cancel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile .wish-card .cancel .cancel-icon {
  font-size: 18px;
}

.profile .wish-card .cancel .cancel-icon:hover{
  color: #ee5247;
}

.profile .wish-card .add-cart-btn {
  background-image: linear-gradient(to right, #a2d858, #51a851);
  color: #fff;
  padding: 8px 32px;
  border: unset;
}

.profile .wish-card .add-cart-btn:hover{
  background-image: unset;
  border: 1px solid #00bc26;
  color: #00bc26;
}

.profile .wish-card .img-box {
  background: aliceblue;
  height: 72px;
  width: 72px;
  overflow: hidden;
  object-fit: contain;
}

.profile .wish-card .img-box img {
  width: 100%;
  height: 100%;
}
.profile .user-info {
  margin-top: 28px;
}

.profile a {
  outline: none !important;
}
.profile {
  padding-top: 38px;
  padding-bottom: 80px;
}

.profile .sidebar-head p{
  font-size: 14px;
  font-family: 'PlayfairDisplay';
  margin: 0;
}

.profile .sidebar-head {
  padding: 12px 10px;
  color: #fff;
  text-align: center;
  background-color: #0B390C;
}

.profile .main-head{
  color: #0B390C;
  font-size: 27px;
  font-family: 'PlayfairDisplay';
  margin-bottom: 24px;
}

.profile .info-profile {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 36px 20px;
}

.profile h2.profile-heading {
  color: #2a263d;
  font-size: 43px;
  font-weight: 700;
}
.profile h3.username {
  color: #2a263d;
  font-family: 'PlayfairDisplay';
  font-size: 20px;
  margin-bottom: 12px;
}

.profile h3.username {
  color: #2a263d;
  font-family: 'PlayfairDisplay';
  font-size: 20px;
  margin-bottom: 24px;
}


.profile .save-cont-prof{
  text-align: center;
}

.profile button.logout-btn {
  background-image: linear-gradient(to right, #A2D858 , #51A851);
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  border: unset;
}
.profile button.logout-btn:hover{
  background-image: unset;
  border: 1px solid #00bc26;
  color: #00bc26;
}
.Accounts {
  padding-top: 40px;
}
.address {
  padding: 30px 0px;
}
.profile li.nav-item a {
  font-size: 16px;
  color: #2a263d;
  font-family: "Montserrat";
  font-weight: 600;
  display: block;
}
.form-group input {
  background-color: transparent;
  border: none;
}
.form-group label {
  font-size: 15px;
  color: #2a263d;
  font-family: "DM Sans";
  font-weight: 400;
}

.form-group input::placeholder {
  color: #000;
}

.password-btn button {
  font-size: 16px;
  color: #a6674a;
  font-family: "DM Sans";
  font-weight: 500;
  text-decoration: underline;
  background: transparent;
  border: none;
}
.for-allign {
  display: flex;
  align-items: center;
}

.first-name h4 {
  font-size: 16px;
  color: #28282896;
  font-family: "GT Walsheim Pro";
  font-weight: 400;
}
.first-name h3 {
  font-size: 20px;
  color: #2a263dc2;
  font-family: "GT Walsheim Pro";
}
.phone h3 a {
  font-size: 20px;
  color: #2a263d;
  font-family: "DM Sans";
  font-weight: 500;
}

.Accounts h5 {
  font-size: 22px;
  color: #2a263d;
  font-family: "AvegasRoyale-reg";
  padding-bottom: 40px;
}
.address h4 {
  font-size: 15px;
  color: #2a263d;
  font-family: "DM Sans";
  font-weight: 400;
}
.address h3 {
  font-size: 20px;
  color: #2a263d;
  font-family: "DM Sans";
  font-weight: 500;
}
.profile .img-and-username-wrapper {
  padding: 11px;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0;
}
.profile-tabs-wrapper {
  padding: 10px 0px;
  border: 1px solid #f2f2f2;
}
.profile-tabs-wrapper .nav-pills .nav-link.active {
  background: transparent;
  color: #0b390c !important;
  font-weight: bold;
}
.wallet-balance-wrapper h3 {
  font-size: 16px;
  font-weight: 500;
  color: #2a263d;
  font-family: "DM Sans";
  line-height: 1;
}
.wallet-balance-wrapper h3 span {
  color: #a6674a;
  font-size: 20px;
  font-family: "DM Sans";
  font-weight: bold;
}
.wallet-balance-wrapper h3 sub {
  color: #a6674a;
  font-size: 10px;
  font-family: "DM Sans";
  font-weight: 400;
  margin-top: -10px;
  display: inline-block;
}

.wallet-balance-wrapper {
  background: #fff9f6;
  padding: 9px;
}
.profile .profile-row {
  padding-top: 100px;
}
.profile-img {
  position: relative;
}
.profile-img .edit-img a {
  position: absolute;
  right: 23%;
  bottom: 2px;
  background: #eeeeee;
  padding: 8px;
  display: flex;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  color: #a6674a;
}
.profile .profile-links-wrapper {
  padding: 30px;
  padding-bottom: 45px;
}
.profile .profile-links-wrapper a.nav-link {
  color: #5e5e5e !important;
  font-size: 17px;
  line-height: 36px;
  font-weight: 400;
  padding: 12px 5px;
  font-family: GT Walsheim Pro;
  border-bottom: 1px solid #ffe9e9;
}
.profile-links-wrapper .active {
  color: #0b390c !important;
  background: transparent;
}
.profile-img a.chnage {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: white;
  height: 20px;
  font-family: "Montserrat";
  font-weight: 600;
  color: #a6674a;
  font-size: 7px;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-img figure {
  position: relative;
  overflow: hidden;
  display: inline-block;
  border: 3px solid #68b653;
  height: 180px;
  width: 180px;
  object-fit: contain;
  border-radius: 50%;
}
.profile-img figure img {
  width: 100%;
  height: 100%;
}

.profile-img {
  position: relative;
  /* margin-top: -130px; */
}
@media (max-width: 926px) {
  .edit-img {
    position: relative;
  }
  .profile-img .edit-img a {
    right: 43%;
  }
}
@media (max-width: 630px) {
  .edit-img {
    position: relative;
  }
  .profile-img .edit-img a {
    right: 43%;
    bottom: -9px;
  }
}
/* account form css starts here */
.account-form-wrapper input {
  border-radius: 0px;
  border: 1px solid #b8b8b8;
}
.account-form-wrapper select {
  border-radius: 0px;
  border: 1px solid #b8b8b8;
}
.profile .account-form-wrapper label {
  color: #2a263d;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}
.profile .account-form-wrapper button.btn-submit {
  background: #a6674a;
  padding: 15px 40px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
}
h3.form-heading {
  font-size: 22px;
  color: #2a263d;
  font-family: "AvegasRoyale-Bold";
  margin-bottom: 0px;
}
.account-form-wrapper form {
  margin-top: 34px;
}
/* account form css ends here */
/* order table starts here */
.order-table-wrapper {
  padding-top: 40px;
}
.profile .order-table-wrapper tr.spacer {
  height: 10px;
  border-right: 0;
  border-left: 0;
}
.order-table-wrapper tr.row1 th {
  padding: 21px 0px;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: #2a263d;
  font-family: "AvegasRoyale-Bold";
}
.order-table-wrapper .confirm-cancel a {
  color: #b2b2b2 !important;
}
.profile-row .pagination-wrapper {
  margin-top: 60px;
}
.order-table-wrapper tr.row2 td {
  padding: 14px 10px;
}
.profile .order-table-wrapper tr.row2 td.date {
  text-align: center;
}
.profile .order-table-wrapper tr.row2 td.total-price {
  text-align: center;
}
.profile .order-table-wrapper tr.row2 td {
  border-bottom: 1px solid #c7c7c79c;
  border-top: 1px solid #c7c7c79c;
  font-family: "DM Sans";
  color: #2a263d;
}
.profile .order-table-wrapper tr.row2 td.confirm {
  color: #00bc26;
  text-align: center;
}
.profile .order-table-wrapper tr.row2 td.confirm i {
  font-size: 20px;
  margin-left: 5px;
}
.profile .order-table-wrapper tr.row2 td.paid.pending i {
  font-size: 20px;
  margin-left: 24px;
}
.profile .order-table-wrapper tr.row2 td.pending {
  color: #c31b01;
}
.order-table-wrapper td.cancel a {
  color: #a6674a;
  font-family: "DM Sans";
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.profile .order-table-wrapper tr.row2 td:first-child {
  border-left: 1px solid #c7c7c79c;
}
.profile .order-table-wrapper tr.row2 td:last-child {
  border-right: 1px solid #c7c7c79c;
}
.order-table-wrapper .row2 td {
  color: #2a263d;
  font-family: "Montserrat";
  font-size: 16px;
}
.profile .order-table-wrapper td.paid {
  color: #00bc26;
}
.order-table-wrapper .order-details a {
  color: #a6674a;
  font-family: "DM Sans";
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
.pagination-wrapper a.page-link {
  display: flex;
  line-height: 0px;
  height: unset;
  margin: 5px;
  padding: 14px;
  justify-content: center;
  font-family: "DM Sans";
  font-size: 18px;
  color: #6e6e6e;
  font-weight: 400 !important;
  font-style: normal !important;
  border: 0px;
}
.pagination li a i {
  line-height: 0px;
}
ul.pagination li.page-item:first-child a.page-link,
ul.pagination li.page-item:last-child a.page-link {
  border-radius: 0px !important;
  background: #ffffff;
  border: 0px;
  color: #6d6d6d;
  font-size: 25px;
}
.pagination-wrapper .active a.page-link {
  color: #fff;
  background: #a6674a;
  border: 1px solid #a6674a;
}
/* order responsive start here */
@media (max-width: 1100px) {
  .order-table-wrapper tr.row1 th {
    padding: 23px 9px;
    font-size: 14px;
  }
  .order-table-wrapper .row2 td {
    font-size: 14px;
  }
}
@media (max-width: 1018px) {
  .order-table-wrapper .row2 td {
    font-size: 13px;
  }
}
@media (max-width: 700px) {
  .order-table-wrapper tr.row2 td {
    padding: 9px 7px;
  }
}
/* order responsive ends here */
/* order table ends here */
/* modal css starts here */
.profile-row button.close span {
  color: #a6674a;
}
.profile .modal-dialog {
  max-width: 41%;
}
.close {
  opacity: 1;
}
.modal-header .close {
  margin: 0;
}
.model-header {
  text-align: center;
  padding: 20px 11px;
}
.profile-row h5#exampleModalLongTitle {
  font-size: 43px;
  font-family: "Montserrat";
  color: #a6674a;
}
.profile-row .model-header h6 {
  color: #2a263d;
  font-family: "Montserrat";
  font-size: 37px;
  font-weight: 400;
}
.model-header button.close {
  margin-top: -92px;
  margin-right: 24px;
}
.product-deatail-wrapper {
  padding: 0px 30px;
  display: flex;
  width: 100%;
  align-items: center;
}
.modal-open .modal {
  overflow: scroll !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.modal-open .modal::-webkit-scrollbar {
  display: none;
}
.product-img {
  flex: 2;
}
.product-content {
  flex: 5;
}
.product-price {
  flex: 1;
}
.profile .modal-body h6 {
  color: #a6674a;
  font-family: "Montserrat";
  font-size: 20px;
}
h6.product-price {
  font-size: 21px;
  font-weight: 600;
}
h6.product-size {
  color: #1f2c35 !important;
  font-weight: 400;
  font-size: 17px !important;
}
h5.product-name {
  font-size: 16px;
  font-weight: 500;
}
.product-deatail-wrapper {
  margin: 20px auto !important;
  width: 80%;
}
.product-deatail-wrapper.active {
  border-right: 3px solid #ef5d61;
  margin: 10px 0px;
}
.order-details-wrapper {
  padding: 0px 32px;
}

.order-details-wrapper h6 {
  display: flex;
  justify-content: space-between;
  color: #000000 !important;
  font-size: 17px !important;
  font-weight: 500;
}
.order-details-wrapper {
  padding: 0px 80px;
}
/* responsive starts here */
@media (max-width: 1345px) {
  .profile .modal-dialog {
    max-width: 51% !important;
  }
}
@media (max-width: 1130px) {
  .profile .modal-dialog {
    max-width: 61% !important;
  }
}
@media (max-width: 936px) {
  .profile .modal-dialog {
    max-width: 71% !important;
  }
}
@media (max-width: 822px) {
  .profile .modal-dialog {
    max-width: 81% !important;
  }
}
@media (max-width: 758px) {
  .product-deatail-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .product-deatail-wrapper.active {
    border-right: none;
    border-bottom: 3px solid #ef5d61;
    margin: 10px 0px;
  }
}
@media (max-width: 563px) {
  .profile .modal-dialog {
    max-width: 81% !important;
    margin: 30px auto;
  }
  .order-details-wrapper h6 {
    display: inline-block;
  }
  .order-details-wrapper {
    padding: 0px 52px;
    text-align: center;
  }
}
/* responsive ends here */
/* modal css ends here */
/* wishlist css starts here */
.wishlist-table-wrapper {
  margin-top: 40px;
}
.wishlist-table-wrapper tr.row1 th {
  padding: 13px;
  background: #f2f2f2;
  font-family: "Montserrat";
  font-size: 12px;
}
.wishlist-table-wrapper tr.row1 th.wish-img {
  width: 16%;
}
.wishlist-table-wrapper td.wishlist-product h4 {
  color: #1f2c35;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}
.wishlist-table-wrapper td.wishlist-price h6 {
  color: #ef5d61;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 600;
}
.wishlist-table-wrapper td.wishlist-cancel i {
  color: #ef5d61;
  font-size: 23px;
}
.wishlist-table-wrapper tr.row2 td {
  margin: 0;
  padding: 0;
}
.wishlist-table-wrapper tr.row2 {
  border-bottom: 1px solid #707070;
}
.wishlist-table-wrapper tr.row2:last-child {
  border-bottom: none;
}
/* responsive css starts here */
@media (max-width: 600px) {
  .wishlist-table-wrapper tr.row1 th {
    text-align: center;
  }

  .wishlist-table-wrapper tr.row2 td {
    text-align: center;
  }
}
/* wishlist css ends here */
/* security form starts here */
.address-form-wrapper form {
  padding-right: 120px;
  padding-bottom: 20px;
}
.saved-address {
  /* padding-right: 120px; */
}
.security-form-wrapper h3.form-heading {
  padding-bottom: 30px;
}
.security-form-wrapper input {
  border-radius: 0px;
  border: 1px solid #b8b8b8;
}
.profile .account-form-wrapper label {
  color: #2a263d;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 500;
}
.profile .security-form-wrapper button.btn-submit {
  background: #a6674a;
  padding: 15px 40px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
}
/* security form ends here */
/* address form starts here */
.address-form-wrapper input {
  border-radius: 0px;
  border: 1px solid #b8b8b8;
}
/* .address-form-wrapper .topbar {
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-bottom: 50px;
  } */
.profile .address-form-wrapper label {
  color: #2a263d;
  font-size: 15px;
  font-weight: 500;
}
.profile .address-form-wrapper button.btn-submit {
  background: #a6674a;
  padding: 15px 25px;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: bold;
}
/* saved adress css starts here */
/* 
    .plans {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    
      max-width: 970px;
      padding: 85px 50px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: #fff;
      border-radius: 20px;
      -webkit-box-shadow: 0px 8px 10px 0px #d8dfeb;
      box-shadow: 0px 8px 10px 0px #d8dfeb;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    } */

/* .plans .plan input[type="radio"] {
    position: absolute;
    opacity: 0;
  } */

.plans .plan {
  cursor: pointer;
  width: 100%;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: 23px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #707070;
  border-radius: 0px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  margin-right: 30px;
  height: 72px;
}

.plans .plan .plan-details span {
  margin-bottom: 10px;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #252f42;
}

/* .container .title {
    font-size: 16px;
    font-weight: 500;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    color: #252f42;
    margin-bottom: 20px;
  } */

.plans .plan .plan-details p {
  color: #646a79;
  font-size: 14px;
  line-height: 18px;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

/* .plans .plan input[type="radio"]:checked + .plan-content:after {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    background: #a6674a;
    right: 20px;
    bottom: 34px;
    border-radius: 100%;
    border: 3px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #a6674a;
    box-shadow: 0px 0px 0px 1px #a6674a;
  } */

/* .plans .plan input[type="radio"] + .plan-content:after {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px; */
/* background: #F35F63; */
/* right: 20px;
      bottom: 39px;
      border-radius: 100%;
      border: 2px solid #fff;
      -webkit-box-shadow: 0px 0px 0px 2px #A6674A;
      box-shadow: 0px 0px 0px 2px #A6674A;
    } */

/* .plans .plan input[type="radio"]:checked + .plan-content {
    border: 2px solid #a6674a;
    -webkit-transition: ease-in 0.3s;
    -o-transition: ease-in 0.3s;
    transition: ease-in 0.3s;
  } */
.saved-address .edits h6 {
  margin-right: 22px;
  font-weight: 600;
  color: #000;
}
@media screen and (max-width: 991px) {
  .plans {
    margin: 0 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 40px;
  }

  .plans .plan {
    width: 100%;
  }

  .plan.complete-plan {
    margin-top: 20px;
  }

  .plans .plan .plan-content .plan-details {
    width: 70%;
    display: inline-block;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 45%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  }
}

@media screen and (max-width: 767px) {
  .plans .plan .plan-content .plan-details {
    width: 60%;
    display: inline-block;
  }
}

@media screen and (max-width: 540px) {
  .plans .plan .plan-content img {
    margin-bottom: 20px;
    height: 56px;
    -webkit-transition: height 0.4s;
    -o-transition: height 0.4s;
    transition: height 0.4s;
  }

  .plans .plan input[type="radio"]:checked + .plan-content:after {
    top: 20px;
    right: 10px;
  }

  .plans .plan .plan-content .plan-details {
    width: 100%;
  }

  .plans .plan .plan-content {
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}
.saved-address h4 {
  color: #262626;
  font-size: 20px;
}
.address-info span.phone {
  margin-left: 13px;
}
.address-info span.states {
  margin-left: 25px;
}
.edits {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.update .remove-add i {
  color: #a6674a;
  font-size: 19px;
  margin: 2px;
}
.address-info h6 {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
.edits h6 {
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 500;
}
@media (max-width: 923px) {
  .address-form-wrapper {
    padding-top: 77px;
  }
  .heading {
    padding-top: 100px;
  }
  .security-form-wrapper {
    padding-top: 90px;
  }
  div#v-pills-order h3.form-heading {
    padding-top: 90px;
  }
}
@media (max-width: 837px) {
  .plans {
    padding: 0px;
  }
}
@media (max-width: 644px) {
  .edits {
    width: 40%;
  }
}
@media (max-width: 563px) {
  .edits {
    width: 40%;
  }
}
@media (max-width: 532px) {
  .edits {
    text-align: left !important;
    width: 100%;
  }
  .edits {
    display: flex;
    flex-direction: row-reverse;
  }
}
/* address form ends here */
/* / 2nd Tab Content Css Start Here / */
.profile .tab-content .card-details {
  background: #f3f3f3;
  padding: 30px 40px;
  border-radius: 20px;
}
.profile .tab-content .card-details .radios {
  opacity: 0;
}
.profile .tab-content .card-details .label figure img {
  width: 100%;
}
.profile
  .tab-content
  .card-details
  input:checked
  ~ span.checkmark
  .label
  figure {
  border: 5px solid #fff;
  box-shadow: 0px 0px 0px 1px #a1c4a9;
}
.profile .tab-content .card-details .paymentCards {
  display: flex;
  align-items: center;
}
.profile .tab-content .card-details .paymentCards label {
  width: 50px !important;
  margin-right: 20px;
}
.profile .tab-content .saveCards {
  padding-top: 30px;
}
/* .Myprofile .tab-content .saveCards .Yourcards {
        background: #F3F3F3;
        padding: 50px 40px;
    } */
.profile .tab-content .Yourcards .cards .radio span.checkmark .label {
  background: transparent;
  width: 27px;
  height: 27px;
  border-radius: 50px;
  border: 2px solid #a1c4a9;
  text-align: center;
  position: relative;
  top: -7px;
}
.profile .tab-content .Yourcards .cards .radio input {
  opacity: 0;
}
.profile .tab-content .Yourcards .cards .radio span.checkmark .label i {
  background: #a1c4a9;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  position: relative;
  top: -2px;
  left: -1px;
  opacity: 0;
}
.profile
  .tab-content
  .Yourcards
  .cards
  .radio
  input:checked
  ~ span.checkmark
  .label
  i {
  opacity: 1;
}
.profile .tab-content .Yourcards .cards {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
}
.profile .tab-content .Yourcards .cards .persnolCard {
  display: flex;
  align-items: center;
}
.profile .tab-content .Yourcards .cards .persnolCard figure img {
  width: 50px;
  margin: 0 10px;
}
.profile .tab-content .Yourcards .cards .persnolCard .form-group label {
  font-size: 10px;
  color: #000000;
  font-family: "trade-bold";
  margin-bottom: 0;
}
.profile .tab-content .Yourcards .cards .persnolCard .form-group input {
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 16px;
  font-family: "trade-bold";
  color: #000;
}
.profile.tab-content
  .Yourcards
  .cards
  .persnolCard
  .form-group
  input:focus-visible {
  outline: none;
}
.profile .tab-content .Yourcards .cards .editBtn a {
  color: #000000;
  font-size: 10px;
  font-family: "trade-reg";
  font-weight: 800 !important;
}
.card-wrapper input[type="radio"] {
  display: none;
}
.card-wrapper input[type="text"] {
  height: 51px;
}
.card-wrapper label {
  color: #2a263d;
  font-family: "Montserrat";
  font-size: 15px;
}
.card-wrapper input[type="radio"]:checked + label img {
  border: 1px solid #a6674a;
}
.card-wrapper button.btn-card {
  background: #a6674a;
  border: 0px;
  color: #fff;
  padding: 14px 51px;
  font-weight: bold;
  font-size: 13px;
  font-family: "Spartan";
}
.saveCards .card-info {
  display: flex;
  align-items: center;
}
.editss {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 40px;
}
.profile .saveCards label.container {
  padding: 0;
}
.profile .saveCards label.container span.checkmark .card-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #707070;
  padding: 10px 20px;
  position: relative;
}
.profile
  .saveCards
  label.container
  input:checked
  ~ span.checkmark
  .card-options {
  box-shadow: 0px 0px 0px 2px #a6674a;
  border-color: #fff;
}
.profile .saveCards label.container span.checkmark .card-options .visa-card {
  display: flex;
  align-items: center;
}
.profile
  .saveCards
  label.container
  span.checkmark
  .card-options
  .visa-card
  .form-group
  label {
  font-size: 8px;
  color: #000000;
}
.profile
  .saveCards
  label.container
  span.checkmark
  .card-options
  .visa-card
  .form-group
  .form-control {
  border: 0;
  font-size: 18px;
  color: #343a40;
  height: 20px;
  padding: 0;
}
.profile
  .saveCards
  label.container
  span.checkmark
  .card-options
  .visa-card
  .img-box {
  margin-right: 20px;
}
.profile .saveCards label.container span.checkmark .card-options .action-btns {
  text-align: right;
}
.profile
  .saveCards
  label.container
  span.checkmark
  .card-options
  .action-btns
  button.delete {
  background: transparent;
  border: 0;
  padding-bottom: 10px;
}
.profile
  .saveCards
  label.container
  span.checkmark
  .card-options
  .action-btns
  button.delete
  i {
  color: #000;
  font-size: 20px;
  padding-bottom: 16px;
}
.profile
  .saveCards
  label.container
  span.checkmark
  .card-options
  .action-btns
  .use-default
  label {
  font-size: 15px;
  color: #1f2c35;
  padding-right: 60px;
}
.profile
  .saveCards
  label.container
  span.checkmark
  .card-options
  .action-btns
  .use-default
  span.check-box {
  width: 18px;
  height: 18px;
  background: #fff;
  position: absolute;
  right: 24px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 0px 1px #a6674a;
}
.profile
  .saveCards
  label.container
  input:checked
  ~ span.checkmark
  .card-options
  .action-btns
  .use-default
  span.check-box {
  background: #a6674a;
}
/* card tab responsive starts here */
@media (max-width: 1100px) {
  .card-wrapper button.btn-card {
    padding: 14px 31px !important;
  }
}
@media (max-width: 630px) {
  .heading {
    padding-top: 60px;
  }
}
/* / 2nd Tab Content Css Start Here / */
/* profile section css ends here */

/* Third Tab Css Start Here */
.profile .loyality_box .topbar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 30px;
}
.profile .loyality_box .topbar h4.title {
  margin: 0;
  font-size: 22px;
  color: #2a263d;
  font-family: "AvegasRoyale-bold";
}
.profile .loyality_box .topbar .button-group .btn {
  padding: 10px 35px;
}
.profile .loyality_box .balance_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile .loyality_box .balance_detail .points_calculator {
  display: flex;
  align-items: center;
  padding-top: 30px;
}
.profile .loyality_box .balance_detail .points_calculator .fileds {
  display: flex;
  align-items: center;
}
.profile
  .loyality_box
  .balance_detail
  .points_calculator
  .fileds
  input.form-control {
  width: 80px;
  border-radius: 0;
  border: 1px solid #00000047;
  margin: 0 5px;
}
.profile .loyality_box .balance_detail .points_calculator .fileds label {
  margin: 0;
}
.profile .loyality_box .balance_detail .points_calculator .button-group {
  margin-left: 20px;
}
.profile
  .loyality_box
  .balance_detail
  .points_calculator
  .button-group
  button.btn
  img {
  margin-left: 10px;
}
.profile .loyality_box .balance_detail .your_balance h5 {
  font-size: 18px;
  font-family: "AvegasRoyale-reg";
}
.profile .loyality_box .balance_detail .your_balance .balance {
  background: #fff9f6;
  padding: 10px 15px;
}
.profile .loyality_box .balance_detail .your_balance .balance h3 {
  color: #a6674a;
  font-size: 40px;
  margin: 0;
}
.profile .loyality_box .balance_detail .your_balance .balance h3 span.points {
  font-size: 16px;
  color: #a6674a;
}
.profile .loyality_box .order-details.table-resposnive {
  margin-top: 20px;
}
.profile .loyality_box .order-details.table-resposnive table.table tr th {
  border: 0;
  text-align: center;
  font-size: 18px;
  font-family: "AvegasRoyale-bold";
}
.profile .loyality_box .order-details.table-resposnive table.table tr td {
  border: 0;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  color: #2a263d;
  padding: 15px 15px;
}
.profile .loyality_box .order-details.table-resposnive table.table tr {
  border: 1px solid #ddd;
}
.profile .loyality_box .order-details.table-resposnive table.table tr.top_row {
  border: 0;
}
.profile
  .loyality_box
  .order-details.table-resposnive
  table.table
  tr.top_row
  th {
  padding: 20px 10px;
}
.profile .loyality_box .order-details.table-resposnive table.table tr.spacer {
  height: 10px;
  border-right: 0;
  border-left: 0;
}
.profile .loyality_box ul.pagination {
  justify-content: end;
}
/* Third Tab Css End Here */

/* Fourth Tab Css Start Here */
.profile .wallet_box .topbar {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 30px;
}
.profile .wallet_box .topbar h4.title {
  margin: 0;
  font-size: 22px;
  color: #2a263d;
  font-family: "AvegasRoyale-bold";
}
.profile .wallet_box .topbar .button-group .btn {
  padding: 10px 35px;
}
.profile .wallet_box .balance_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile .wallet_box .balance_detail .your_balance h5 {
  font-size: 18px;
  font-family: "AvegasRoyale-reg";
}
.profile .wallet_box .balance_detail .your_balance .balance {
  background: #fff9f6;
  padding: 10px 15px;
}
.profile .wallet_box .balance_detail .your_balance .balance h3 {
  color: #a6674a;
  font-size: 40px;
  margin: 0;
}
.profile .wallet_box .balance_detail .your_balance .balance h3 span.points {
  font-size: 16px;
  color: #a6674a;
}
.profile .wallet_box .order-details.table-resposnive {
  margin-top: 20px;
}
.profile .wallet_box .order-details.table-resposnive table.table tr th {
  border: 0;
  text-align: left;
  font-size: 18px;
  font-family: "AvegasRoyale-bold";
}
.profile .wallet_box .order-details.table-resposnive table.table tr td {
  border: 0;
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  color: #2a263d;
  padding: 15px 20px;
}
.profile .wallet_box .order-details.table-resposnive table.table tr {
  border: 1px solid #ddd;
}
.profile .wallet_box .order-details.table-resposnive table.table tr.top_row {
  border: 0;
}
.profile .wallet_box .order-details.table-resposnive table.table tr.top_row th {
  padding: 20px 20px;
}
.profile .wallet_box .order-details.table-resposnive table.table tr.spacer {
  height: 10px;
  border-right: 0;
  border-left: 0;
}
.profile .wallet_box ul.pagination {
  justify-content: end;
}
