.shop {
  padding: 120px
}

.shop .cate-box {
    background-color: #0B390C;
    padding: 12px 25px;
    color: #fff;
    /* text-align: center; */
}

.shop .cate-box .title{
  font-size: 14px;
    color: #ffff;
    margin: 0;
    font-family: 'PlayfairDisplay';
}

.cate-box ul {
  padding: 0;
  margin: 0;
}
.cate ul {
  padding: 0;
  margin: 0;
}
.cate ul li {
  list-style: none;
  position: relative;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}
.cate ul li:before {
  content: '';
  background: #000;
  width: 8px;
  height: 8px;
  position: absolute;
  left: 0;
  top: 24px;
  border-radius: 49%;
}

.shop select{
  padding: 6px 25px;
    /* width: 24%; */
    border-color: #b3adad;
}

.shop .select-container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.shop .shop-dropCollection{
  display: flex;
  justify-content: end;
  align-items: center;
}