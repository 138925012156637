.type::after {
    content: '|';
    animation: cursor 1.1s infinite step-start;
  }
  
  @keyframes cursor {
    50% {
      opacity: 0;
    }
  }


/* Modal  */
.modal-content {
    padding: 18px 40px;
    /* text-align: center; */
    width: 550px;
 }
 
 .orderView-Modal{
    padding: 20px 0px;
 }

 .orderView-Modal .head-modal{
    text-align: center;
 }

 .orderView-Modal .head-modal h2{
    color: #0B390C;
 }
 
 .orderView-Modal .modal-close{
    position: absolute;
    right: 0px;
    margin-right: 15px;
    font-size: 18px;
 }
 
 .orderView-Modal .view-product-card{
    display: flex;
    justify-content: start;
    padding: 22px 22px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    margin: 6px 0px;
 }

 .orderView-Modal .view-product-card .content{
    margin-left: 20px;
 }

 .orderView-Modal .view-product-card .content p{
   margin: 0;
   font-size: 13px;
   color: #274D5E;
   font-family: 'GT Walsheim Pro';
 }

 .orderView-Modal .view-order-bottom{
   display: flex;
   justify-content: space-between;
   align-items: center;
 }

 .orderView-Modal .ContinueBtn{
   background: #0B390C;
    padding: 10px 20px;
    font-family: "GT Walsheim Pro black";
    color: #fff;
    font-size: 16px;
    margin-right: 18px;
    width: 100%;
    text-align: center;
 }

 .orderView-Modal .view-order-bottom .smallPara{
   font-size: 15px;
   color: #274D5E;
   font-family: 'GT Walsheim Pro';
   margin: 0px;
   padding: 15px 0px;
 }

.page-title{
    background-color: #1B1C1E;
    height: 44vh;
}

.page-title .title{
    font-family: 'PlayfairDisplay';
    font-size: 45px;
    color: #fff;
    text-align: center;
}

.page-title .url-container {
    padding: 70px 0px;
}

a.page-link{
    border-radius: unset !important;
    background: unset !important;
    border: 1px solid #0B390C !important;
    color: unset !important;
    font-size: unset !important;
    color: #0B390C !important;
}

.page-item {
    padding: 4px !important;
}

.page-item.active .page-link {
    z-index: 1 !important;
    color: #fff !important;
    background-color: #0B390C !important;
    border-color: #0B390C !important;
}